<template>
<span>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 744.09447 1052.362179" :width="width" :height="height" :style="{top: topPx, left: leftPx}">
  <defs>
    <filter id="ah">
    <feGaussianBlur stdDeviation=".10493"/>
    </filter>
    <filter id="ag">
    <feGaussianBlur stdDeviation=".14911"/>
    </filter>
    <filter id="h">
    <feGaussianBlur stdDeviation=".15094"/>
    </filter>
    <filter id="al">
    <feGaussianBlur stdDeviation=".14603"/>
    </filter>
    <filter id="am">
    <feGaussianBlur stdDeviation=".19096"/>
    </filter>
    <filter id="ao">
    <feGaussianBlur stdDeviation=".29144"/>
    </filter>
    <filter id="g">
    <feGaussianBlur stdDeviation=".18002"/>
    </filter>
    <filter id="ak">
    <feGaussianBlur stdDeviation=".28665"/>
    </filter>
    <filter id="a"/>
    <filter id="aj" x="-.02387" y="-.10043" width="1.0477" height="1.2009">
    <feGaussianBlur stdDeviation=".13989"/>
    </filter>
    <filter id="an" x="-.72679" y="-.02436" width="2.4536" height="1.0487">
    <feGaussianBlur stdDeviation=".15719"/>
    </filter>
    <filter id="ai" x="-.05973" y="-.13305" width="1.1194" height="1.2661">
    <feGaussianBlur stdDeviation="2.38616"/>
    </filter>
    <linearGradient id="c">
    <stop offset="0"/>
    <stop stop-opacity="0" offset="1"/>
    </linearGradient>
  </defs>
  <path transform="matrix(6.968 0 0 7.1641 -8911.7 -4137.4)" d="m1281.8 669.91 53.192 20.647 42.693-29.395-53.192-13.648-42.693 22.396z" fill-rule="evenodd" filter="url(#ai)" opacity=".75581"/>
  <g transform="matrix(15.815 0 0 15.815 -15731 -2436.6)">
    <path d="m997.1 177.34v17.517l22.434 8.1471 17.322-11.58v-16.641l-17.808 9.4395-21.947-6.882z" :fill="fill1" fill-rule="evenodd"/>
    <path d="m997.28 194.51 22.069 8.2646v-17.074l-22.069-6.7207v15.53z" :fill="fill2" fill-rule="evenodd" filter="url(#ak)" opacity=".52536"/>
    <path transform="matrix(1.1383 0 0 1.1383 -122.16 -68.53)" d="m1002.7 222.42h0.43929v15.805h-0.43929z" :fill="fill2" filter="url(#an)" opacity=".52174"/>
    <path d="m1014.8 167.96 22.488 3.5454-0.0553 6.0396-17.697 8.956-22.81-7.0124 0.03716-6.1742 18.037-5.3544z" :fill="fill1"/>
    <path d="m996.92 175.07 0.09082 4.3594 22.16 6.9024 0.0909-5.086-22.342-6.1758z" :fill="fill2" fill-rule="evenodd" filter="url(#g)" opacity=".5"/>
    <path d="m997.08 173.49 22.371 5.9407 17.588-7.6939-0.0224 0.45696-17.306 7.6695-0.0856 6.2298h-0.5191l-9e-4 -6.2298-21.999-5.8465-0.02609-0.52675z" :fill="fill2" fill-rule="evenodd" filter="url(#ao)"/>
    <path d="m1023.9 170.19-18.317 5.772-0.2486 21.614 4.6796 1.8718 0.1817-22.148 18.642-7.0797-4.9383-0.02934z" fill-rule="evenodd" filter="url(#am)"/>
    <path d="m1024.2 169.43-18.746 6.1899-0.2791 22.238 4.6575 1.6733 0.2343-22.574 18.808-6.8161-4.6744-0.7108z" :fill="fill2" fill-rule="evenodd"/>
    <path transform="matrix(-1 .24329 0 1 2052.2 -250.39)" d="m1014.7 176.33 0.3586 4.6081 17.807 4.5416 0.0909-5.086-18.256-4.0637z" :fill="fill2" fill-rule="evenodd" filter="url(#g)" opacity=".5"/>
    <path d="m1005.3 170.83 21.722 5.5672 0.0414 21.425 4.2276-2.8171-0.258-20.547-21.529-4.7506-4.2034 1.122z" fill-rule="evenodd" filter="#ca0a0f"/>
    <path d="m1005.3 170.78 21.951 5.44-0.0829 21.715 4.1655-2.7964-0.0716-20.857-21.631-4.8269-4.3307 1.3255z" :fill="fill2" fill-rule="evenodd"/>
    <path d="m1010.1 164.99s0.2181 1.802 1.4707 3.4605c0.5944 0.78714 2.8981 2.1196 2.8981 2.1196l1.2545 1.514s-1.2977 0.43256-1.9466 0.34605-3.8675-1.0204-5.0787-1.7125-3.374-3.1315-3.5038-3.6938-0.7272-2.0246-0.2081-2.9762 1.5236-1.3934 1.8264-1.4367 3.1577 1.4275 3.1577 1.4275l0.1298 0.95164z" :fill="fill2" fill-rule="evenodd"/>
    <path d="m1005.6 175.93 4.1583 1.0225-0.2727 11.589-4.022-3.5448 0.1364-9.0664z" :fill="fill2" fill-rule="evenodd" filter="#ca0a0f" opacity=".59058"/>
    <path d="m1005.3 195.27-0.0748 2.5268 4.5108 1.6284 0.0374-2.7888-4.4734-1.3664z" :fill="fill2" fill-rule="evenodd"/>
    <path transform="matrix(-.82832 0 0 .82832 1869 .31984)" d="m1011.5 210.58 4.0583 1.8229-0.1727 11.188-4.0219-3.5448 0.1363-9.4666z" :fill="fill2" fill-rule="evenodd" filter="url(#h)" opacity=".67391"/>
    <g transform="matrix(.99533 .096578 -.096578 .99533 21.678 -97.313)" fill-rule="evenodd">
    <path d="m1015 173.21s1.9083-0.14136 2.191 0.98952-0.6361 2.4738 1.767 4.6649c0.7068-0.3534 0.7775-1.0602 0.7775-1.0602l1.8377 0.77748s-1.4843-2.1911-1.4843-3.1099 0.212-2.0497-0.4241-2.4031-3.746-0.3534-3.746-0.3534l-0.9188 0.49476z" filter="url(#a)" opacity=".9529"/>
    <path d="m1015.1 173.08s1.9083-0.14136 2.191 0.98952-0.6361 2.4738 1.767 4.6649c0.7068-0.3534 0.7775-1.0602 0.7775-1.0602l1.8377 0.77748s-1.4843-2.1911-1.4843-3.1099 0.212-2.0497-0.4241-2.4031-3.746-0.3534-3.746-0.3534l-0.9188 0.49476z" :fill="fill2"/>
    </g>
    <path d="m1010.4 171.77s-1.8377 0.91884-2.6858 1.2016-5.5131-0.07068-5.5131-0.07068l2.4031 0.77748-0.6361 1.4136s3.5238 0.03441 4.8063-0.14136c1.8919-0.25932 2.4738-1.767 5.0889-1.8377 1.5688-0.0424 2.4032 0.14136 2.4032 0.14136s-2.1204-1.6963-5.8665-1.4843z" fill-rule="evenodd" filter="url(#aj)"/>
    <path d="m1008.6 163.52s-1.1018-0.62958-1.5215-0.62958-1.9412 0.57711-2.0986 1.3641-0.3673 2.4134 0.3673 3.3577 1.8362 1.9412 1.8362 1.9412-1.6628-3.1658-0.9443-4.6169c0.7878-1.5909 2.4133-1.4166 2.3609-1.4166z" :fill="fill2" fill-rule="evenodd" filter="url(#ag)"/>
    <path d="m1009.3 161.52c-1.8818 0.1321-3.8698 1.4578-4.5312 2.5938-0.8819 1.5145 0.507 4.2447 2.5484 5.8226 2.7976 2.1624 6.9828 2.243 6.9828 2.243 0-0.06523 2.7338 0.75564 1.625-2.4406-1.592-4.589-2.6677-6.83-4.8125-7.9062-0.5492-0.27561-1.1852-0.35653-1.8125-0.3125zm-2.6313 1.792c1.9084-0.59502 3.1003 1.9974 3.9438 3.0518 1.0382 1.2977 3.6563 5.125 3.6563 5.125l0.5312 0.6875s-0.2893 0.0046-3.1875-0.6875-4.9823-2.6556-5.0688-2.8286c-1.7268-2.0494-2.235-4.5796 0.125-5.3482z" :fill="fill2" fill-rule="evenodd"/>
    <path d="m1010.7 171.54s-1.8377 0.91884-2.6859 1.2016-5.513-0.07068-5.513-0.07068l2.4031 0.77748-0.6361 1.4136s3.5238 0.03441 4.8062-0.14136c1.892-0.25932 2.4738-1.767 5.089-1.8377 1.5688-0.0424 2.4031 0.14136 2.4031 0.14136s-2.1204-1.6963-5.8664-1.4843z" :fill="fill2" fill-rule="evenodd"/>
    <path d="m1019.9 171.39s-3.0658-1.435-3.7833-1.435-1.8916 1.3698-1.5655 2.2178 2.8701 1.435 3.6528 1.435c0.697-0.17877 1.3308-0.39979 2.0221-0.71753 0-0.4566-0.3261-1.5003-0.3261-1.5003z" fill-rule="evenodd" filter="url(#a)"/>
    <path d="m1011.9 167.01 2.7814 4.3808 1.3908-1.043s-1.3908-3.1291-2.1557-4.5198-1.3212-2.0166-1.3212-2.0166 1.1822 2.9205 0.904 3.4768-1.4603-0.27815-1.5993-0.27815z" :fill="fill2" fill-rule="evenodd" filter="url(#ah)"/>
    <path d="m1019.8 171.31c-0.3089-0.26101-3.1337-1.5199-3.8512-1.5199s-1.8916 1.3698-1.5655 2.2178 2.8701 1.435 3.6528 1.435c0.697-0.17877 1.3308-0.39979 2.0221-0.71753 0-0.4566 0.1358-0.96683-0.2582-1.4154z" :fill="fill2" fill-rule="evenodd"/>
    <path d="m1018.6 173.42s2.2467 0.8236 3.6309 1.0399 4.1985 0.69036 4.8906 0.43082 4.4554-1.0382 4.9745-1.86-2.7684-0.95164-2.7684-0.95164-1.8601 1.1679-3.1578 1.2544-5.1042-0.82187-5.1042-0.82187l-2.4224 0.64885-0.0432 0.25954z" :fill="fill2" fill-rule="evenodd"/>
    <path d="m1015.6138,169.97593c0,0 .3477-.16297.6448-.06787 .4241.13575.7643.1869 1.6798.57692 .9873.42059 1.6537.75134 1.7216.80225 .5576.47906.178.98646.1958,1.53936-.3394.16968-1.3914.50904-1.5441.54298-.7065-.65205.0824-1.75706 1.0518-1.84004-.1282-.21455-1.2056-.73421-1.4607-.85297-.8282-.32936-1.746-.64973-1.746-.64973s-.8478-.105-1.2218.61086c.0849-.35634.7127-.69569.6788-.66176z" fill="url(#r)" fill-rule="evenodd" filter="url(#a)"/>
    <path d="m1019 173.12 2.1196-0.51907s3.4605-2.9414 3.8498-3.1145c1.9938-1.6435 4.482-2.2504 6.5317-1.514 0 0-0.2163-2.6386-0.9084-2.9847s-2.5954-0.60559-2.5954-0.60559-2.5744 1.7264-2.8772 2.0291l-4.7064 4.6314-1.3773 1.6192-0.2754 0.56514 0.239-0.10706z" :fill="fill2" fill-rule="evenodd"/>
    <path d="m1029.8 163.55c-3.374 0.21628-9.9919 8.0562-10.381 8.4888s-0.978 1.3653-0.9348 1.322c0 0 4.2136 1.8743 7.8472 1.658s8.1798-2.4104 8.6545-5.5397c0.5458-3.597-1.8117-6.1453-5.1857-5.929zm-2.2982 1.4924c1.1398-0.20797 3.5794-0.22585 3.142 3.4138-0.2708 2.2534-1.8881 5.827-5.2188 6s-6.586-1.2675-6.5428-1.3108c0 1e-5 5.2952-5.61 5.5116-5.783s1.8037-2.0023 3.108-2.3201z" :fill="fill2" fill-rule="evenodd"/>
    <path d="m1004.723,164.65577c-.1477-.81079 1.467-2.00561 2.831-2.53026s3.0661-.3563 3.0661-.3563-1.4949.67108-2.1136,1.12369c-1.2466-.12671-2.3898.40758-2.3898.40758s-.7192.27735-1.3937,1.35529z" :fill="fill2" fill-rule="evenodd" filter="url(#a)" opacity=".55797"/>
    <path transform="matrix(.97476 .22327 -.22327 .97476 62.78 -225.49)" d="m1026.8 165.52c0.833-1.1376 1.272-1.644 2.917-2.1309 1.4015-0.41477 3.7517 0.66958 3.7517 0.66958s-1.2915 1.3749-1.9102 1.8275c-0.6837-0.93412-2.499-1.2178-2.499-1.2178s-1.0552-0.14341-2.2595 0.85157z" fill="url(#n)" fill-rule="evenodd" filter="url(#a)" opacity=".55797"/>
    <path d="m1030.8 165.7s0.7138 1.757 0.3295 3.2395-1.0433 2.361-1.0433 2.361 2.0316 0.38435 3.02-0.05491 1.5374-1.2629 1.5374-1.2629 0.4392-2.4708-0.1648-3.6239-1.3177-1.4276-1.3177-1.4276-1.4825 0-2.3611 0.7687z" :fill="fill2" fill-rule="evenodd" filter="url(#a)"/>
  </g>
  </svg>
</span>
</template>

<script>
import ThemeMixin from '../theme_mixin'

export default {
  name: 'present',

  mixins: [ThemeMixin]
}
</script>
